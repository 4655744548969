.body-client {
  .btn-spinner {
    left: -20px;
  }

  .awn-toast {
    &.awn-toast-success {
      color: white;
      background-color: #42453e;

      .awn-toast-progress-bar:after {
        background: transparent;
      }

      .awn-toast-label, .fa {
        color: white;
      }

      .awn-toast-wrapper {
        border-color: transparent;
      }
    }
  }

  #div-products-count {
    margin-right: 10px;
  }

  .form-control {
    height: calc(1.5em + 1.5rem + 1px);
    padding: 0.75rem 0.75rem;
    font-size: 14px;
  }

  a {
    color: #252525;
  }

  a:hover {
    color: #7fad39;
  }

  .breadcrumb__option a {
    color: #ffffff;

    &:after {
      right: -16px;
    }
  }

  .product__item__pic__hover {
    position: absolute;
    left: 0;
    bottom: -50px;
    width: 100%;
    text-align: center;
    -webkit-transition: all, 0.5s;
    -moz-transition: all, 0.5s;
    -ms-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;

    li {
      list-style: none;
      display: inline-block;
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }

      &:hover a.circle {
        background: #7fad39;
        border-color: #7fad39;

        i {
          color: #ffffff;
          transform: rotate(360deg);
        }
      }

      a.circle {
        i {
          position: relative;
          transform: rotate(0);
          -webkit-transition: all, 0.3s;
          -moz-transition: all, 0.3s;
          -ms-transition: all, 0.3s;
          -o-transition: all, 0.3s;
          transition: all, 0.3s;
        }
      }
    }
  }

  .sidebar {
    .sidebar__item {
      a {
        padding-right: 10px;
      }

      ul {
        ul {
          margin-left: 10px;
          position: relative;
          padding-left: 10px;
          padding-right: 10px;

          &:before {
            content: "";
            height: 85%;
            width: 3px;
            position: absolute;
            background-color: #ebebeb;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }

          ul {
            margin-left: 10px;
          }
        }
      }

      li {
        a[data-toggle=collapse]:after {
          font-family: "Font Awesome 6 Free";
          content: "\f107" !important;
          font-weight: 900;
          margin-left: auto;
        }

        a[data-toggle=collapse][aria-expanded=true]:after {
          font-family: "Font Awesome 6 Free";
          content: "\f106" !important;
          font-weight: 900;
          margin-left: auto;
        }

        a.active {
          font-weight: bold;

          &.bg {
            background-color: #ebebeb;
            padding-left: 10px;
          }
        }

        a.padding {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  .filter__found {
    text-align: right;
  }

  .text-red {
    color: #dd2222;
  }

  .text-grey {
    color: #6f6f6f;
  }

  .checkout__order {
    color: #6f6f6f;

    .product-title {
      line-height: 24px;
      font-size: 16px;
    }

    .product-sum {
      font-weight: 700;
      text-align: right;
    }

    .checkout__order__subtotal {
      margin-bottom: 0;
    }

    .checkout__order__plain {
      font-size: 18px;
      color: #1c1c1c;
      font-weight: 700;
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .checkout__order__total {
      padding-top: 15px;
      border-top: 1px solid #e1e1e1;
    }
  }

  .checkout__form {
    h2 {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  .checkout__input {
    input, textarea, select {
      width: 100%;
      border: 1px solid #ebebeb;
      padding-left: 20px;
      font-size: 16px;
      color: #6b6b6b;
      border-radius: 4px;
    }

    input {
      height: 46px;
    }

    textarea {
      padding: 20px;
    }
  }

  .fs-13 {
    font-size: 13px;
  }

  .checkout__order .checkout__order__subtotal span {
    float: none;
  }

  .checkout__order .checkout__order__total {

    span {
      float: none;
    }
  }

  h4 {
    color: #1c1c1c;
    font-weight: 700;
    margin-bottom: 25px;
  }

  .table-orders {
    width: 100%;

    tr {
      td {
        .order-title {

        }
      }
    }
  }


  #div-orders-results {
    font-size: 14px;

    a[data-toggle=collapse]:after {
      font-family: "Font Awesome 6 Free";
      content: "\f107" !important;
      font-weight: 900;
    }

    a[data-toggle=collapse][aria-expanded=true]:after {
      font-family: "Font Awesome 6 Free";
      content: "\f106" !important;
      font-weight: 900;
    }

    .div-order {
      border: 1px solid #ececec;
    }

    .div-order-title {

    }

    .div-order-summary {

    }

    .div-order-comment {
      color: #6f6f6f;
      font-size: 15px;
    }

    .div-order-products {
      width: 200px;
    }

    .div-order-icon {
      width: 20px;
    }

    .div-order-products-list {
      border-top: 1px solid #ececec;
      border-bottom: 1px solid #ececec;

      .div-order-product-photo {
        width: 100px;
        height: 100px;

        img {
          width: 100%;
          aspect-ratio: 1;
        }
      }

      .div-order-product-total-price {
        width: 100px;
        text-align: right;
      }
    }
  }

  @media (max-width: 1000px) {
    #div-orders-results {
      .div-order-products {
        display: none;
      }

      .div-order-products-small {
        display: block;
      }

      .div-order-icon {
        align-items: start;
      }
    }
  }

  @media (min-width: 1000px) {
    #div-orders-results {
      .div-order-products {
        display: block;
      }

      .div-order-products-small {
        display: none;
      }

      .div-order-icon {
        align-items: center;
      }
    }
  }

  .btn {
    &.no-hover {
      &:focus {
        box-shadow: none;
      }
    }
  }

  #carousel-banners {
    .carousel-indicators {
      bottom: 20px;

      li {
        background-color: #6f6f6f;
      }
    }

    .carousel-control-next, .carousel-control-prev {
      color: #6f6f6f;
      width: 10%;
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
      background-image: none;
    }

    .hero__item {
      padding-left: 75px;
      padding-right: 75px;

      .hero__text {
        width: 300px;

        .title {
          font-size: 40px;
          color: #252525;
          line-height: 52px;
          font-weight: 700;
          margin: 10px 0;
        }
      }

      &.left {}

      &.center {
        justify-content: center;

        .hero__text {
          text-align: center;
        }
      }

      &.right {
        justify-content: end;

        .hero__text {
          text-align: right;
        }
      }
    }
  }
}

.div-product-weight-container {
  text-align: start;
}

.shoping__cart__quantity {
  .pro-qty {
    width: 225px !important;

    input {
      width: 160px !important;
    }
  }
}

.no-stock {
  filter: grayscale(1);
}

.div-no-stock {
  color: #6f6f6f;
}

#form-checkout {
  .choices {
    margin-bottom: 0;
  }
}

.w-50px {
  width: 50px !important;
  aspect-ratio: 1;
}

.only-mobile {
  display: none;
}

.mobile {
  .only-mobile {
    display: block;
  }
}

.progressive {
  display: block;
  outline: none;
  overflow: hidden;
  position: relative
}

.progressive img {
  border: 0;
  display: block;
  height: auto;
  max-width: none;
  width: 100%
}

.progressive img.preview {
  filter: blur(2vw);
  transform: scale(1.05)
}

.progressive img.reveal {
  animation: progressiveReveal 1s linear;
  left: 0;
  position: absolute;
  top: 0
}

@keyframes progressiveReveal {
  0% {
    opacity: 0;
    transform: scale(1.05)
  }
  to {
    opacity: 1;
    transform: scale(1)
  }
}
